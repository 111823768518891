<template>
    <div v-if="isBrowserCompatible" id="app">
        <!-- Modali: -->
        <support-modal-component
            ref="supportModalComponent"
        ></support-modal-component>
        <standard-modal type="subject" ref="subjectModal" />
        <standard-modal
            type="action"
            actionTitle="Odjava"
            actionMessage="Da li ste sigurni da želite da se odjavite?"
            :actionButtons="[
                { text: 'Zatvori', variant: 'green' },
                { text: 'Potvrdi', variant: 'red' },
            ]"
            @Zatvori="$refs.logoutModal.hideModal()"
            @Potvrdi="korisnikOdjava()"
            ref="logoutModal"
        />
        <!-- <modal-subjekti ref="modalSubjekti" /> -->
        <modal-podloga
            ref="modalOdjava"
            naslov="Odjava"
            znak="exclamation-diamond"
            boja="warning"
            tekst="Da li ste sigurni da želite da se odjavite?"
            d1="Odustani"
            dp="Odjavi me"
            @dp_c="korisnikOdjava()"
        />
        <!-- -->

        <!-- <div v-if="this.$route.path !== '/'">
            <div class="obuhvat">
                <div>
                    <img
                        class="logo"
                        height="50"
                        src="../assets/logo_white.png"
                        alt="AMIGO - Logo"
                    />
                </div>
                <div class="mreza">
                    <div class="grad1"></div>
                    <div class="grad3"></div>
                    <div></div>
                    <div class="grad2"></div>
                    <div></div>
                    <div class="grad1"></div>

                    <div class="grad1"></div>
                    <div class="grad5"></div>
                    <div class="grad1"></div>
                    <div class="grad3"></div>
                    <div class="grad2"></div>
                    <div class="grad3"></div>

                    <div class="grad2"></div>
                    <div class="grad4"></div>
                    <div class="grad6"></div>
                    <div></div>
                    <div class="grad1"></div>
                    <div></div>
                </div>
                <b-navbar
                    toggleable="lg"
                    type="dark"
                    variant="primary"
                >
                    <b-navbar-nav>
                        <b-nav-item v-b-toggle.sidebar-backdrop>
                            <b-icon
                                class="meni"
                                scale="1.25"
                                icon="list"
                                variant="light"
                            ></b-icon>
                        </b-nav-item>
                    </b-navbar-nav>
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item
                            class="pointer subjekat-nav"
                            @click="$refs.modalSubjekti.prikaziModal()"
                        >
                            <b-button
                                class="dugme-nav"
                                variant="light"
                            >
                                <b-icon
                                    scale="0.8"
                                    icon="briefcase"
                                    class="subjekat-nav"
                                ></b-icon>
                                <span>
                                    {{ $store.state.subjekatIzabrani.ime_skraceno }}
                                </span>
                            </b-button>
                        </b-nav-item>
                    </b-navbar-nav>
                </b-navbar>
            </div>
            <div>
                <b-sidebar
                    id="sidebar-backdrop"
                    aria-labelledby="sidebar-no-header-title"
                    backdrop-variant="transparent"
                    backdrop
                    no-header
                    shadow
                >
                    <b-navbar fixed="top" class="sidebar_header">
                        <b-icon
                            icon="person-circle"
                            scale="1.5"
                            class="mr-3 avatar"
                        ></b-icon>
                        <span class="ime">{{ $store.state.korisnikIme }}</span>
                        <b-icon
                            v-b-toggle.sidebar-backdrop
                            icon="chevron-double-left"
                            scale="1.2"
                            class="gasi-sidebar pointer"
                        ></b-icon>
                    </b-navbar>
                    <b-list-group
                        class="lista-strana border-0 rounded-0 text-left"
                    >
                        <b-list-group-item
                            class="lista"
                            :to="{
                                name: 'Pregled',
                                params: {
                                    mb: $store.state.subjekatIzabrani.mb,
                                },
                            }"
                        >
                            <b-icon
                                scale="1.7"
                                icon="grid"
                                class="mr-3"
                            ></b-icon
                            >&nbsp;Pregled
                        </b-list-group-item>
                        <b-list-group horizontal
                            @mouseover="prikazPlus('plus-kom')"
                            @mouseleave="sakrijPlus('plus-kom')"
                        >
                            <b-list-group-item
                                style="width: 100%"
                                :to="{
                                    name: 'Komitenti',
                                    params: {
                                        mb: $store.state.subjekatIzabrani.mb,
                                    },
                                }"
                            >
                                <b-icon
                                    scale="1.7"
                                    icon="building"
                                    class="mr-3"
                                ></b-icon
                                >&nbsp;Komitenti
                            </b-list-group-item>
                            <b-list-group-item class="ml-auto"
                                style="width: 25%; whitespace: nowrap"
                                :to="{
                                    name: 'KomitentiObrada',
                                    params: {
                                        bd: 0,
                                    },
                                }"
                            >
                                <b-icon
                                    id="plus-kom"
                                    style="opacity: 0"
                                    scale="2.5"
                                    icon="plus"
                                    class="mr-3 mt-1 float-right pointer"
                                ></b-icon>
                            </b-list-group-item>
                        </b-list-group>
                        <b-list-group horizontal
                            @mouseover="prikazPlus('plus-dok')"
                            @mouseleave="sakrijPlus('plus-dok')"
                        >
                            <b-list-group-item
                                style="width: 100%"
                                :to="{
                                    name: 'Dokumenti',
                                    params: {
                                        mb: $store.state.subjekatIzabrani.mb,
                                    },
                                }"
                            >
                                <b-icon
                                    scale="1.7"
                                    icon="archive"
                                    class="mr-3"
                                ></b-icon
                                >&nbsp;Dokumenti
                            </b-list-group-item>
                            <b-list-group-item class="ml-auto"
                                style="width: 25%; whitespace: nowrap"
                                :to="{
                                    name: 'DokumentiObrada',
                                    params: {
                                        mb: $store.state.subjekatIzabrani.mb
                                    },
                                }"
                            >
                                <b-icon
                                    id="plus-dok"
                                    style="opacity: 0"
                                    scale="2.5"
                                    icon="plus"
                                    class="mr-3 mt-1 float-right pointer"
                                ></b-icon>
                            </b-list-group-item>
                        </b-list-group>
						<b-list-group horizontal
                            @mouseover="prikazPlus('plus-kat')"
                            @mouseleave="sakrijPlus('plus-kat')"
                        >
                            <b-list-group-item
                                style="width: 100%"
                                :to="{
                                    name: 'Katalog',
                                    params: {
                                        mb: $store.state.subjekatIzabrani.mb,
                                    },
                                }"
                            >
                                <b-icon
                                    scale="1.7"
                                    icon="card-list"
                                    class="mr-3"
                                ></b-icon
                                >&nbsp;Katalog
                            </b-list-group-item>
                            <b-list-group-item class="ml-auto"
                                style="width: 25%; whitespace: nowrap"
                                :to="{
                                    name: 'SifreObrada',
                                    params: {
                                        bs: 0,
                                    },
                                }"
                            >
                                <b-icon
                                    id="plus-kat"
                                    style="opacity: 0"
                                    scale="2.5"
                                    icon="plus"
                                    class="mr-3 mt-1 float-right pointer"
                                ></b-icon>
                            </b-list-group-item>
                        </b-list-group>
                        <br />
                        <b-list-group-item
                            class="lista pointer"
                            v-b-toggle.sidebar-backdrop
                            @click="$refs.modalSubjekti.prikaziModal()"
                        >
                            <b-icon
                                scale="1.7"
                                icon="briefcase"
                                class="mr-3"
                            ></b-icon
                            >&nbsp;Promena subjekta
                        </b-list-group-item>
                        <b-list-group-item
                            class="lista pointer"
                            @click="$refs.modalOdjava.prikaziModal()"
                        >
                            <b-icon
                                scale="1.7"
                                icon="box-arrow-right"
                                class="mr-3"
                            ></b-icon
                            >&nbsp;Odjavi se
                        </b-list-group-item>
                    </b-list-group>
                    <img
                        class="sidebar-logo"
                        height="25"
                        src="../assets/logo_grey.png"
                        alt="Amigo logo"
                    />
                </b-sidebar>
            </div>
        </div> -->
        <maintenance-view v-if="$route.path == '/maintenance'" />
        <login v-else-if="$route.path == '/'" />
        <div v-else class="app-view">
            <modal-component
                v-if="$store.state.korisnikM"
                title="Dobrodošli Amigo!"
                ref="welcomeModal"
                instant
                :actions="[{ caption: 'Započnimo!', eventName: 'close' }]"
                @close="endM()"
            >
                <template>
                    <p class="xbec__modal__message x-fg-regular">
                        Hvala Vam što ste odlučili da isprobate naš program. Na
                        raspolaganju Vam je DEMO nalog kako bi ste se bolje
                        upoznali sa njim i njegovim funkcijama dok mi pripremamo
                        radni prostor za Vaše preduzeće.
                    </p>
                    <p class="xbec__modal__message x-fg-regular">
                        S obzirom da je ovo DEMO nalog, imate punu slobodu da
                        radite šta god želite bez ikakve brige.
                    </p>
                    <p class="xbec__modal__message x-fg-regular">
                        Čim radni prostor za Vašu firmu bude spreman, mi ćemo
                        Vas obavestiti kako bi mogli početi sa upravljanjem.
                    </p>
                    <p class="xbec__modal__message x-fg-regular">
                        Za sva pitanja stojimo na raspolaganju. Možete nas
                        kontaktirati putem dugmeta
                        <i class="fa-solid fa-comments"></i> na glavnoj bočnoj
                        traci.
                    </p>
                    <p class="xbec__modal__message x-fg-regular">
                        - Vaš Amigo tim
                    </p>
                </template>
            </modal-component>
            <div
                v-if="$route.name == 'DocumentListView'"
                class="xbec__page-header__background"
            >
                <div class="xbec__page-header__blender"></div>
            </div>
            <div class="app-sidebar">
                <glavna-bocna-traka
                    @ps="$refs.subjectModal.showModal()"
                    @po="$refs.logoutModal.showModal()"
                    ref="refMainSidebar"
                />
            </div>
            <div class="app-routerview">
                <router-view
                    @ps="$refs.subjectModal.showModal()"
                    @po="$refs.logoutModal.showModal()"
                    :class="{ prikaz: primeniPrikaz }"
                    :key="viewReRenderKey"
                />
                <notification-center
                    ref="notificationArea"
                ></notification-center>
            </div>
        </div>

        <footer
            v-if="
                !['Login', 'DokumentiPrikaz', 'MaintenanceView'].includes(
                    $route.name
                )
            "
            class="text-center py-2"
        >
            Copyright 2010 - 2023 Rezidual<br />
            <small><small>Build: 18.03.2025. 13:53:34 #160</small></small>
        </footer>
    </div>
    <div v-else id="app">
        <div class="incompatible-browser-view">
            <h1>Amigo nije dostupan pomoću ovog pretraživača</h1>
            <p class="message">
                Nažalost u ovom trenutku, neke funkcije koje Amigo pruža nije
                izvodljivo omogućiti pomoću ovog pretraživača. Kako bi mogli
                koristiti Amigo aplikaciju, molimo Vas da web-stranicu otvorite
                u nekom od sledećih pretraživača:
            </p>
            <p class="signature">
                <i class="fa-brands fa-chrome"></i>&nbsp;Google Chrome |
                <i class="fa-brands fa-firefox-browser"></i>&nbsp;Mozilla
                Firefox
            </p>
        </div>
    </div>
</template>

<script>
import CRUD from "@/crud.js";
import { store } from "@/store/index";
import Login from "./views/Login.vue";
// import ModalSubjekti from "./components/App/ModalSubjekti.vue";
import StandardModal from "./components/StandardModal.vue";
import ModalPodloga from "./components/Modal/ModalPodloga.vue";
import GlavnaBocnaTraka from "./components/App/GlavnaBocnaTraka.vue";
import NotificationCenter from "./components/App/NotificationCenter.vue";
import SupportModalComponent from "@/components/App/SupportModalComponent.vue";
import MaintenanceView from "./views/MaintenanceView.vue";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
    store,
    components: {
        Login,
        StandardModal,
        ModalPodloga,
        GlavnaBocnaTraka,
        NotificationCenter,
        SupportModalComponent,
        MaintenanceView,
        ModalComponent,
    },
    data() {
        return {
            gbtLock: false,
            // primeniPrikaz: false,

            modalPodloga: false,

            prozor: null,

            useState: "idle",

            isBrowserCompatible: null,
        };
    },
    computed: {
        primeniPrikaz: function () {
            if (
                this.$route.name == "Login" ||
                typeof this.$route.query.pd != "undefined"
            ) {
                return false;
            }
            return true;
        },
    },
    watch: {
        // "$route.name"() {
        //     if (this.$route.name !== "Login") {
        //         this.primeniPrikaz = true;
        //     } else if (this.$route.name == "Login") {
        //         this.primeniPrikaz = false;
        //     }
        // },
        $route() {
            if (this.$refs.notificationArea) {
                this.$refs.notificationArea.hideNotificationCenter();
            }
            if (this.loadUserDataFromLocalStorage()) {
                if (this.$route.path === "/") {
                    this.$router.push({
                        name: "Pregled",
                        params: {
                            mb: this.$store.state.subjekatIzabrani.mb,
                        },
                    });
                }
            } else if (this.$route.name != "MaintenanceView") {
                this.$router.push({
                    name: "Login",
                });
            }
            this.useState = "idle";
        },
    },
    methods: {
        loadUserDataFromLocalStorage: function () {
            const userData = JSON.parse(localStorage.getItem("korisnik"));
            if (!userData) return undefined;
            this.$store.state.korisnikSubjekti = [...(userData.subjekti ?? [])];
            this.$store.state.subjekatIzabrani = this.getActiveSubjectData();
            this.$store.state.korisnikIme = userData.ime ?? "missing";
            this.$store.state.korisnikUuid = userData.uuid ?? "missing";
            this.$store.state.korisnikReferal = userData.referal ?? "missing";
            this.$store.state.korisnikToken = userData.token ?? "missing";
            if (userData.M) this.$store.state.korisnikM = userData.M;
            window.console.log("store", this.$store.state);
            return true;
        },
        getActiveSubjectData: function () {
            window.console.log("gasd", this.$route.params.mb);
            return Object.assign(
                {},
                this.$store.state.korisnikSubjekti.find((subject) => {
                    return Number(subject.mb) === Number(this.$route.params.mb);
                }) ?? this.$store.state.korisnikSubjekti[0]
            );
        },
        endM: function () {
            this.$refs.welcomeModal.hideModal();
            delete this.$store.state.korisnikM;
            let tmpKorisnik = JSON.parse(localStorage.getItem("korisnik"));
            delete tmpKorisnik.M;
            localStorage.setItem("korisnik", JSON.stringify(tmpKorisnik));
        },
        showSupportModal: function (initialReason) {
            this.$refs.supportModalComponent.showModal(initialReason);
        },
        showNotificationCenter: function () {
            this.$refs.notificationArea.showNotificationCenter();
        },
        prikaziModalSubjekti: function () {
            this.$refs.subjectModal.showModal();
        },
        prikaziModalOdjava: function () {
            this.$refs.modalOdjava.prikaziModal();
        },
        prikazPlus: function (id) {
            document.getElementById(id).style.opacity = 1;
        },
        sakrijPlus: function (id) {
            document.getElementById(id).style.opacity = 0;
        },
        korisnikOdjava: function () {
            localStorage.removeItem("korisnik");
            this.$refs.logoutModal.hideModal();
            this.$router.push({
                name: "Login",
            });
        },
        obradiProzor: function () {
            let sirina = window.innerWidth;
            if (sirina <= 768) {
                this.prozor = "sm";
            }
            if (sirina > 768 && sirina <= 1024) {
                this.prozor = "md";
            }
            if (sirina > 1024) {
                this.prozor = "lg";
            }
        },
        showMainSidebar: function () {
            this.$refs.refMainSidebar.openSidebarOnMobile();
        },
        checkBrowserCompatibility: function () {
            this.isBrowserCompatible =
                window.navigator.userAgent.includes("Chrome") ||
                window.navigator.userAgent.includes("Firefox");
            window.console.log("ibc", this.isBrowserCompatible);
        },
    },
    mounted() {
        this.checkBrowserCompatibility();
        this.obradiProzor();
        window.addEventListener(
            "resize",
            function () {
                this.obradiProzor();
            }.bind(this)
        );
        window.addEventListener("click", (event) => {
            if (!event.target.closest(".xbec__dropdown"))
                this.hideAllElements(".xbec__dropdown");
        });
        // if (this.$route.path == "/" && localStorage.getItem("korisnik")) {
        //     let korisnik = JSON.parse(localStorage.getItem("korisnik"));
        //     // window.console.log("korisnik", korisnik);
        //     if (korisnik.subjekti !== undefined) {
        //         this.$store.state.korisnikSubjekti = Array.from(
        //             korisnik.subjekti
        //         );
        //     }
        //     if (korisnik.ime !== undefined) {
        //         this.$store.state.korisnikIme = korisnik.ime;
        //     }
        //     if (korisnik.uuid !== undefined) {
        //         this.$store.state.korisnikUuid = korisnik.uuid;
        //     }
        //     if (korisnik.referal !== undefined) {
        //         this.$store.state.korisnikReferal = korisnik.referal;
        //     }
        //     if (korisnik.M !== undefined) {
        //         window.console.log("M", korisnik.M);
        //         this.$store.state.korisnikM = korisnik.M;
        //     }
        //     this.subjekat = this.$store.state.subjekatIzabrani = Object.assign(
        //         {},
        //         this.$store.state.korisnikSubjekti[0]
        //     );
        //     window.console.log("app: $store.state", this.$store.state);
        //     if (this.$refs.notificationArea) {
        //         this.$refs.notificationArea.collapseSupportWindow();
        //     }
        //     this.useState = "idle";
        //     this.$router.push({
        //         name: "Pregled",
        //         params: {
        //             mb: this.$store.state.subjekatIzabrani.mb,
        //         },
        //     });
        // }
        // this.$refs.welcomeModal.showModal();
    },
    beforeCreate() {
        this.viewReRenderKey = 0;
        new CRUD()
            .read("503")
            .then((response) => {
                window.console.log(
                    "503",
                    response,
                    (response || {}).data?.greska || !response
                );
                if ((response || {}).data?.greska || !response) {
                    throw new Error("503");
                }
            })
            .catch((error) => {
                window.console.log(error);
                this.$router.push({
                    name: "MaintenanceView",
                });
            });
    },
    beforeUpdate() {
        this.viewReRenderKey += 1;
    },
    updated() {
        let user = JSON.parse(localStorage.getItem("korisnik")) ?? {};
        this.$store.state.debug = ["Nikola Arula", "Luka Arula"].includes(
            user.ime
        );
    },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@700&display=swap");

html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    background-color: var(--clr-gray-100) !important;
    font-family: "Poppins", sans-serif !important;

    .app-routerview {
        margin-left: 70px;
    }

    .prikaz {
        margin: 0 3rem;
    }
}

*::-webkit-scrollbar {
    height: 0;
    width: 10px;
}
*::-webkit-scrollbar-track {
    background: #c2c2d321;
}
*::-webkit-scrollbar-thumb {
    background-color: #c2c2d371;
    border-radius: 20px;
    border: 3px solid #c2c2d321;
}

@media screen and (max-width: 768px) {
    .app-routerview {
        margin-left: 0 !important;
    }

    .prikaz {
        margin: 10px !important;
    }
}

i.fa-solid.fa-spinner {
    animation: vrtenje 2s infinite linear;
}

@keyframes vrtenje {
    from {
        transform: rotate(0deg);
        transform-origin: center;
    }
    to {
        transform: rotate(360deg);
        transform-origin: center;
    }
}

.xbec__page-header__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 275px;
    background: rgb(62, 156, 232);
    background: linear-gradient(
        45deg,
        rgba(62, 156, 232, 1) 0%,
        rgba(105, 182, 245, 1) 19%,
        rgba(59, 150, 224, 1) 34%,
        rgba(65, 155, 227, 1) 44%,
        rgba(92, 174, 241, 1) 65%,
        rgba(156, 207, 249, 1) 95%,
        rgba(156, 207, 249, 1) 100%
    );
    -webkit-box-shadow: inset 0px -25px 100px -68px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0px -25px 100px -68px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0px -25px 100px -68px rgba(0, 0, 0, 0.5);
    z-index: 1;

    // .xbec__page-header__blender {
    //     position: absolute;
    //     top: 125px;
    //     left: 0;
    //     right: 0;
    //     height: 75px;
    //     background: linear-gradient(
    //         180deg,
    //         #f7f9fc00 0%,
    //         var(--clr-gray-100) 80%
    //     );
    // }
}

.incompatible-browser-view {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h1 {
        font-weight: 600;
        color: #42a5f5;
    }

    .message {
        font-size: 0.95rem;
        line-height: 1.45;
        opacity: 0.8;
        max-width: 850px;
        margin: 2rem auto;

        @media (max-width: 870px) {
            width: calc(100vw - 20px);
        }
    }
}
</style>
